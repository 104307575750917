<template>
  <div class="relative">
    <!-- 视频 -->
    <div class="">
      <div class="relative default" v-if="!videoUrl.length">
        <img :src="videoDetail.photoUrl" class="w100" />
        <!-- <img src="@/assets/img/icon984.png" class="w100" /> -->
        <span class="absolute pause" @click="requestVideo"></span>
      </div>
      <div v-show="videoUrl.length">
        <video
          ref="videoRef"
          id="videoId"
          :src="videoUrl"
          width="100%"
          autoPlaywebkit-playsinline="true"
          x-webkit-airplay="true"
          playsinline="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          height="100%"
          preload="auto"
          controls
          muted
        ></video>
      </div>
    </div>
    <!-- 顶部悬浮信息 -->
    <div
      class="fixed left0 top0 w100 px20 py10 top-fixed font14 vertical between"
      @click="preDownload"
      v-show="visibleTop"
    >
      <div class="cf vertical">
        <img src="@/assets/img/icon982.png" :style="{ width: '35px' }" />
        <span class="ml8">舒昕逸家让生活变得更轻松</span>
      </div>
      <div class="vertical">
        <span class="cf open">打开看看</span>
        <img
          class="ml10"
          src="@/assets/img/close.png"
          :style="{ width: '20px' }"
          @click="
            (e) => {
              e.stopPropagation();
              this.visibleTop = false;
            }
          "
        />
      </div>
    </div>
    <!-- 右侧悬浮功能按钮 -->
    <div class="fixed right15 right-fixed">
      <div class="relative commet1" @click="preDownload">
        <div class="w50px oh" :style="{ borderRadius: '50%' }">
          <img :src="videoDetail.photoPath" class="w100" />
          <!-- <img src="@/assets/img/icon978.png" :style="{ width: '50px' }" /> -->
        </div>
        <img
          src="@/assets/img/icon975.png"
          class="absolute img2"
          :style="{ width: '20px' }"
        />
      </div>
      <div class="relative tc cf mt30 commet2" @click="preDownload">
        <img src="@/assets/img/icon979.png" :style="{ width: '40px' }" />
        <div>{{ futureThousand(videoDetail.likeNum) }}</div>
      </div>
      <div class="relative tc cf mt20 commet3" @click="preDownload">
        <img src="@/assets/img/icon980.png" :style="{ width: '40px' }" />
        <div>{{ futureThousand(videoDetail.commentNum) }}</div>
      </div>
      <div class="relative tc cf mt20 commet4" @click="preDownload">
        <img src="@/assets/img/icon981.png" :style="{ width: '40px' }" />
        <div>{{ futureThousand(videoDetail.shareNum) }}</div>
      </div>
    </div>
    <!-- 底部悬浮信息 -->
    <div class="fixed left0 w100 bottom0 bottom-fixed">
      <div class="p20 cf info" v-show="visibleBottom">
        <div class="vertical row">
          <span class="font16">@{{ videoDetail.techName }} </span>
          <span class="font12 vertical">
            <img
              class="ml10"
              src="@/assets/img/icon977.png"
              :style="{ width: '12px' }"
            />
            <span class="ml4" :style="{ color: 'rgba(255,255,255,.4)' }">
              {{ videoDetail.provinceName }}{{ videoDetail.cityName
              }}{{ videoDetail.districtName }}
            </span>
          </span>
        </div>
        <div class="mt6 row vertical">
          <span class="font14">{{ videoDetail.catalogFirstName }}</span>
          <span class="ml6 font12">#{{ videoDetail.catalogSecondName }}</span>
        </div>
        <div class="mt6 vertical between">
          <div>
            <img
              class="relative top3"
              src="@/assets/img/icon976.png"
              :style="{ width: '16px' }"
            />
            <span class="ml6 font14">{{ videoDetail.selectedMusicName }}</span>
            <!-- <span class="ml4" :style="{ color: 'rgba(255,255,255,.4)' }">
            — 晴天（周杰伦）
          </span> -->
          </div>
          <span class="font14" @click="preDownload">立即预约</span>
        </div>
      </div>
      <div class="tc cf vote" @click="preDownload">为TA投票</div>
    </div>
    <!-- 错误提示框 -->
    <div
      class="fixed top0 left0 w100 h100 opacity-wrapper"
      v-show="hasError"
      @click="clearMsg(0)"
    >
      <div class="fixed tc py50 cf error">{{ msg }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "share",
  data() {
    return {
      hasError: false,
      msg: "",
      videoUrl: "",
      videoDetail: {},
      visibleTop: true,
      visibleBottom: true,
    };
  },
  methods: {
    showMsg(msg = "") {
      this.msg = msg;
      this.hasError = true;
      this.clearMsg();
    },
    clearMsg(time = 3000) {
      setTimeout(() => {
        this.hasError = false;
      }, time);
    },
    futureThousand(val) {
      val = val + "";
      if (val.length > 4) {
        return (val / 10000).toFixed(2) + "万";
      } else {
        return val;
      }
    },
    requestVideo() {
      this.$axios({
        url: this.$requests.admin.getVideoPlayInfo,
        method: "post",
        data: { videoId: sessionStorage.getItem("videoId") },
      })
        .then((res) => {
          if (res.data.code !== 200) {
            this.stopTimer();
            this.showMsg(res.data.message || "请求失败");
            return;
          }
          let playInfoList = res.data.data.playInfoList;
          this.videoUrl = playInfoList[0].playURL;
        })
        .catch((err) => {});
    },
    getVideoDetail() {
      this.$axios({
        url: this.$requests.tech.videoDetail,
        method: "post",
        data: { videoId: sessionStorage.getItem("videoId") },
      })
        .then((res) => {
          if (res.data.code !== 200) {
            this.stopTimer();
            this.showMsg(res.data.message || "请求失败");
            return;
          }
          this.videoDetail = res.data.data || {};
        })
        .catch((err) => {});
    },
    preDownload() {
      let brower = this.$utils.judgeBrower();
      if (brower == "ios") {
        console.log("是ios");
        var loadDateTime = new Date();
        window.location = "chumuser://"; //用户端
        // window.location = "chumdrop://"; //技师端
        window.setTimeout(function() {
          //如果没有安装app,便会执行setTimeout跳转下载页
          var timeOutDateTime = new Date();
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location = "https://apps.apple.com/cn/app/id1533676322"; //ios下载地址
          } else {
            window.close();
          }
        }, 500);
      } else if (brower == "android") {
        console.log("是android");
        try {
          window.location = "chumuser://"; //用户端
          // window.location = "chumdrop://"; //技师端
          window.setTimeout(function() {
            window.location =
              "https://a.app.qq.com/o/simple.jsp?pkgname=com.chumo.app.user"; //android下载地址
          }, 500);
        } catch (e) {}
      }
    },
  },
  mounted() {
    let vue = this;
    let { videoId } = this.$route.query;
    if (videoId) {
      sessionStorage.setItem("videoId", videoId);
    } else {
      sessionStorage.setItem("videoId", "b979db195c7842bd845b8062f7bd996d");
    }
    this.getVideoDetail();
    document.getElementById("videoId").onplay = function() {
      vue.visibleBottom = false;
    };
    document.getElementById("videoId").onpause = function() {
      vue.visibleBottom = true;
    };
  },
};
</script>
<style lang="less" scoped>
.pause {
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url(../../assets/img/icon983.png) no-repeat center;
  background-size: 100% 100%;
}
.top-fixed {
  background: rgba(0, 0, 0, 0.3);
  .open {
    padding: 5px 15px;
    border-radius: 20px;
    background: rgba(241, 72, 73, 1);
  }
}
.right-fixed {
  top: 50%;
  height: 300px;
  margin-top: -150px;
  z-index: 5;
  .commet1 {
    .img2 {
      bottom: -10px;
      left: 50%;
      margin-left: -10px;
    }
  }
}
.bottom-fixed {
  .info {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
  .vote {
    background: rgba(241, 72, 73, 1);
    line-height: 56px;
  }
}
</style>
